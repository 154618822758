import { gql } from 'apollo-boost';
import { GraphQLGetOrgsForUserCode } from '../types';

const GET_ORGANISATIONS_FROM_USER_CODE = gql`
    query getOrgsForUserCode($email: String!, $code: String!) {
        getOrgsForUserCode(email: $email, code: $code) {
            organisationId
            name
            alias
            imageUrl
        }
    }
`;

export default (():GraphQLGetOrgsForUserCode => ({
  query: GET_ORGANISATIONS_FROM_USER_CODE,
  module: 'identity',
  buildVariables: ({ email, code }: { email: string; code: string }) => ({
    email,
    code
  })
}));
