import React, { FC, memo } from 'react';
import { History } from 'history';
import WorkspaceForm from './WorkspaceForm';
import WorkspaceWrapper from './WorkspaceWrapper';
import GetOrganisationNameFromAlias from '../../types/GetOrganisationNameFromAlias';

import '../../styles/_login.scss';

interface WorkspaceProps {
  getOrganisationNameFromAlias: GetOrganisationNameFromAlias;
  history: History;
}

const Workspace: FC<WorkspaceProps> = ({
  getOrganisationNameFromAlias,
  history
}) => (
  <WorkspaceWrapper backgroundType="person">
    <WorkspaceForm
      history={history}
      getOrganisationNameFromAlias={getOrganisationNameFromAlias}
    />
  </WorkspaceWrapper>
);

export default memo<WorkspaceProps>(Workspace);
