import { gql } from 'apollo-boost';
import { GraphQLRefreshToken } from '../../../types';

const REFRESH_TOKEN_QUERY = gql`
  query {
    refreshToken {
      auth
      token
      user {
        firstName
        lastName
        userId
        email
        imageUrl
        email
        preferences
        isAvailable
        initialSetup
      }
    }
  }
`;

export default (): GraphQLRefreshToken => ({
  query: REFRESH_TOKEN_QUERY,
  module: 'identity',
  ignoreCache: true,
  buildVariables: () => ({})
});
