import React, { FC } from 'react';
import { credentials } from '@shootsta/client-auth';
import { RequestProvider } from '@shootsta/common-react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash.get';
import ChooseWorkspaceComponent from '../components/ChooseWorkspace';
import { getUserOrganisationsApi } from '../api';

import GetUserOrganisations from '../types/GetUserOrganisations';
import { Credentials } from '../../../types';

interface IRequests {
  getUserOrganisations: GetUserOrganisations;
}

const ChooseWorkspace: FC = () => {
  const history = useHistory();
  return (
    <RequestProvider requests={{
      getUserOrganisations: getUserOrganisationsApi()
    }}
    >
      {(requests: IRequests) => {
        const { user }: Credentials = credentials.get();

        return (
          <ChooseWorkspaceComponent
            email={_get(user, 'email')}
            history={history}
            {...requests}
          />
        );
      }}
    </RequestProvider>
  );
};

export default ChooseWorkspace;
