import getAlias from '@shootsta/get-alias';
import { RETURN_TO } from '../common/constants';

export default function getWorkspaceAlias(): string | null {
  const queryParams = (new URL(window.location.href)).searchParams;
  const returnTo = queryParams.get(RETURN_TO);

  if (!returnTo) {
    return null;
  }

  return getAlias(`${window.location.protocol}//${returnTo}`);
}
