import React, { ChangeEvent, Component, ReactNode } from 'react';
import { Button, Input, Icon, Text } from '@shootsta/common-react';
import { History } from 'history';
import LoginHeader from '../LoginHeader';
import LoginHelp from '../LoginHelp';
import { getBaseDomain, getWorkspaceUrl } from '../../../../../utils';
import { onEnterPressed } from '../../utils';
import GetOrganisationNameFromAlias from '../../types/GetOrganisationNameFromAlias';
import { ALIAS, RETURN_TO } from '../../../../constants';
import config from '../../../../../config';

import '../../styles/_login-form.scss';

type WorkspaceFormProps = {
  getOrganisationNameFromAlias: GetOrganisationNameFromAlias;
  history: History;
};

type WorkspaceFormState = {
  workspace: string;
  error: string;
  submitting: boolean;
};

export default class WorkspaceForm extends Component<WorkspaceFormProps, WorkspaceFormState> {
  constructor(props: WorkspaceFormProps) {
    super(props);

    this.state = {
      workspace: '',
      error: '',
      submitting: false
    };
  }

  handleWorkspaceChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ workspace: value, error: '' });
  };

  handleSubmit = async (): Promise<void> => {
    const { getOrganisationNameFromAlias } = this.props;
    const { workspace } = this.state;

    if (!this.canSubmit(workspace)) {
      return;
    }

    this.setState({
      error: '',
      submitting: true
    });

    const alias = workspace.toLowerCase();
    const result = await getOrganisationNameFromAlias({ alias });

    if (!result || result.error || !result.data || !result.data.getOrganisationNameFromAlias) {
      this.setState({
        error: 'Oops! Looks like the workspace you entered is not valid or does not exist',
        submitting: false
      });

      return;
    }
    const workspaceUrl = getWorkspaceUrl(alias);
    const returnToMinusProtocol = workspaceUrl.replace(/^https?:\/\//, '');
    const queryParams = new URLSearchParams({
      [RETURN_TO]: returnToMinusProtocol
    });

    window.location.assign(`${window.location.origin}/?${queryParams.toString()}&${ALIAS}=${alias}`);
  };

  handleKeyDown = onEnterPressed({ onClick: this.handleSubmit });

  canSubmit = (workspace: string): boolean => Boolean(workspace);

  render(): ReactNode {
    const { history } = this.props;
    const { workspace, submitting, error } = this.state;
    const urlSuffix = getBaseDomain(config.basicWorkspaceUrl);

    return (
      <div className="login-form">
        <div className="login-form__body">
          <LoginHeader
            title="Welcome to Shootsta"
            subtitle="Sign in to your workspace"
          />
          {error ? (
            <div className="login-form__body__error">
              <Icon name="alert" color="thunderBird" size={15} />
              <p>{error}</p>
            </div>
          ) : null}
          <form className="login-form__body__form">
            <Input
              data-cy="workspace-alias-input"
              data-testid="workspace-alias-input"
              id="workspace-input"
              className="login-form__body__field login-form__body__workspace"
              placeholder="your-workspace"
              autoGrow
              suffixIcon={(
                <Text highlighted>
                  .
                  {urlSuffix}
                </Text>
              )}
              value={workspace}
              onChange={this.handleWorkspaceChange}
              label="Enter your workspace's Shootsta URL"
              autoComplete="workspace"
              onKeyDown={this.handleKeyDown}
            />
          </form>
          <Button
            data-cy="workspace-submit"
            className="login-form__body__submit"
            disabled={!this.canSubmit(workspace)}
            onClick={this.handleSubmit}
            busy={submitting}
          >
            Continue
          </Button>
          <LoginHelp
            workspaceLinkText="Not sure which is your workspace?"
            history={history}
          />
          <div className="login-form__footer" />
        </div>
      </div>
    );
  }
}
