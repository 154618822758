import { gql } from 'apollo-boost';
import { GraphQLGetUserOrganisation } from '../types';

const GET_USER_ORGANISATIONS = gql`
    query getUserOrganisations($email: String!) {
        getUserOrganisations(email: $email) {
            organisationId
            name
            alias
            imageUrl
        }
    }
`;

export default (():GraphQLGetUserOrganisation => ({
  query: GET_USER_ORGANISATIONS,
  module: 'users',
  buildVariables: ({ email }) => ({
    email
  })
}));
