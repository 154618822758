import { gql } from 'apollo-boost';
import { GraphQLChangePassword } from '../types';

const CHANGE_PASSWORD_QUERY = gql`
    mutation changePassword($password: String!) {
        changePassword(password: $password) {
            email
        }
    }
`;

export default (): GraphQLChangePassword => ({
  mutation: CHANGE_PASSWORD_QUERY,
  module: 'identity',
  buildVariables: (password: string) => ({ password })
});
