import React from 'react';
import { Loader, useApi } from '@shootsta/common-react';
import type { History } from 'history';
import queryString from 'query-string';
import { useUpdateEffect } from 'react-use';
import { credentials } from '@shootsta/client-auth';
import { AUTHORIZATION_CODE } from '../../../common/constants';
import type { AuthorizationCodeLoginRequest } from '../../../common/types';

interface AuthorizationCodeLoginProps {
  history: History;
  authorizationCodeLogin: AuthorizationCodeLoginRequest;
}

export const ROOT_ROUTE = '/';
export const AUTHORIZATION_CODE_LOGIN_DATA_PATH = 'authorizationCodeLogin';

const navigateRoot = (history: History) => {
  history.push(ROOT_ROUTE);
};

const AuthorizationCodeLogin: React.FC<AuthorizationCodeLoginProps> = ({
  history,
  authorizationCodeLogin
}) => {
  const {
    [AUTHORIZATION_CODE]: authorizationCode
  } = queryString.parse(window.location.search) as {
    [AUTHORIZATION_CODE]: string
  };

  if (!authorizationCode) {
    navigateRoot(history);
    return null;
  }

  const { data, error } = useApi({
    dataBasePath: AUTHORIZATION_CODE_LOGIN_DATA_PATH,
    fn: () => authorizationCodeLogin({ authorizationCode })
  }, [authorizationCode]);

  useUpdateEffect(() => {
    if (data) {
      credentials.set(data);
    }

    navigateRoot(history);
  }, [data, error, history]);

  return (
    <Loader
      text={null}
      caption={null}
      hasNoBeforeShowTimeout
    />
  );
};

export default AuthorizationCodeLogin;
