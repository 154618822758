import React, { Component } from 'react';
import { History } from 'history';
import { Error, Loader } from '@shootsta/common-react';
import ChooseWorkspaceList from './ChooseWorkspaceList';
import GetUserOrganisationsRequest, { UserOrganisation } from '../../types/GetUserOrganisations';
import { RoutePath } from '../../../../enums/RoutePath';

interface ChooseWorkspaceProps {
  getUserOrganisations: GetUserOrganisationsRequest;
  email?: string;
  history: History;
}

interface ChooseWorkspaceState {
  organisations: Array<UserOrganisation>;
  loading: boolean;
  error: boolean;
}

export default class ChooseWorkspace extends Component<ChooseWorkspaceProps, ChooseWorkspaceState> {
  constructor(props: ChooseWorkspaceProps) {
    super(props);

    this.state = {
      organisations: [],
      loading: true,
      error: false
    };
  }

  componentDidMount(): void {
    this.fetchOrganisations();
  }

  fetchOrganisations = async (): Promise<void> => {
    const { getUserOrganisations, email } = this.props;
    if (!email) {
      return void this.setState({ loading: false });
    }

    const { data, error } = await getUserOrganisations({ email });

    if (error || !data || !data.getUserOrganisations) {
      return void this.setState({ loading: false, error: true });
    }

    this.setState({ organisations: data.getUserOrganisations, loading: false });
  };

  useAnotherAddress = (): void => {
    const { history } = this.props;

    history.push(RoutePath.GetWorkspaces);
  };

  render(): JSX.Element {
    const { organisations, loading, error } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Error />;
    }

    return (
      <ChooseWorkspaceList
        organisations={organisations}
        useAnotherAddress={this.useAnotherAddress}
      />
    );
  }
}
