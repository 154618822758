import React from 'react';
import { Icon, Input, Button, Text, Link } from '@shootsta/common-react';
import '../styles/_welcome-guest.scss';

const HELP_URL = 'https://help.shootsta.com/en';

function openHelpPage() {
  window.open(HELP_URL, '_blank');
}

type AnonymousUserFormProps = {
  goToLogin: () => void,
  handleAnonymousUser: () => Promise<void>,
  updateFirstName: ({ target: { value } }: { target: { value: string; }; }) => void,
  updateLastName: ({ target: { value } }: { target: { value: string; }; }) => void,
  busy: boolean,
  error?: string,
  disabled?: boolean
};

export default function AnonymousUserForm({
  goToLogin,
  handleAnonymousUser,
  updateFirstName,
  updateLastName,
  busy,
  error,
  disabled
}: AnonymousUserFormProps): JSX.Element {
  return (
    <>
      <div className="welcome-guest__header">
        <Icon
          name="projects"
          size={15}
          color="white"
        />
        <div className="welcome-guest__title">
          <p className="welcome-guest__primary-title">Hello there!</p>
          <p className="welcome-guest__secondary-title">
            Please enter your
            <b>&nbsp;FULL NAME&nbsp;</b>
            or if you have an account, log back into the HUB
          </p>
        </div>
      </div>
      <div className="welcome-guest__body">
        <div className="welcome-guest__body__left-content">
          <Input
            autoFocus
            data-testid="first-name-input"
            labelProps={{ required: true }}
            label="First Name"
            className="welcome-guest__body__input"
            onChange={updateFirstName}
          />
          <Input
            labelProps={{ required: true }}
            label="Last Name"
            className="welcome-guest__body__input"
            onChange={updateLastName}
          />
          <Button
            disabled={disabled}
            className="welcome-guest__body__button"
            onClick={handleAnonymousUser}
            busy={busy}
          >
            Start Reviewing
          </Button>
          <Text centered>or</Text>
          <Button
            type="SECONDARY"
            className="welcome-guest__body__button"
            onClick={goToLogin}
          >
            Log Back In
          </Button>
        </div>
        <div className="welcome-guest__body__right-content">
          <Text heading5>
            A brighter, more accessible content library
          </Text>
          <Text label>
            We continue to disrupt the video production industry by combining tools, technology,
            education and expertise to simplify the video production process.
            We enable people everywhere to make quality video content at scale,
            ready to share in just 24 hours.
          </Text>
          <Text label>
            We call it the perfect blend between in-house and outsourced video production.
            That’s Video Simplified.
          </Text>
        </div>
      </div>
      <div className="welcome-guest__footer">
        {/* eslint-disable-next-line */}
        <Link
          className="welcome-guest__footer__link"
          onClick={openHelpPage}
        >
          Need Help?
        </Link>
      </div>
      {error
        ? <Text danger prefixIcon="alert" prefixIconColor="redApple">Something went wrong</Text>
        : null}
    </>
  );
}
