import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

import ForgotPasswordComponent from '../components/ForgotPassword';
import { loginApi, forgotPasswordApi } from '../../../api';
import { ForgotPasswordFunction } from '../../../types';

type RequestProps = {
  forgotPassword: ForgotPasswordFunction
};

const ForgotPassword = ({ history }: { history: History }) => (
  <RequestProvider
    requests={{
      login: loginApi(),
      forgotPassword: forgotPasswordApi()
    }}
  >
    {(props: RequestProps) => (
      <ForgotPasswordComponent
        history={history}
        forgotPassword={props.forgotPassword}
      />
    )}
  </RequestProvider>
);

export default withRouter(ForgotPassword);
