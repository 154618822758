module.exports = {
  sentryDSN: 'https://410f869116bd48af997bf825989aa035@sentry.io/1293271',
  sentryReleasePrefix: 'defaultd@',
  apiEndpoint: 'https://api.develop.shootsta.com/',
  serviceEndpoint: 'https://service-defaultd.develop.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaultd.id.develop.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso-id.develop.shootsta.com/saml',
  shootstaDomainSegments: 'app,develop,staging',
  skipNotifications: 'false',
  defaultReturnToSuccessEndpoint: 'https://develop.shootsta.com',
  basicWorkspaceUrl: 'https://develop.shootsta.com',
  socialGoogleClientId: '693034939601-auh968kjd49iei7u3hn005mcitnqh3va.apps.googleusercontent.com',
  socialLinkedInClientId: '86twx8izjl20oc',
  socialAppleClientId: 'com.shootsta.platform',
  recaptchaSiteKey: '6Lc-P2wmAAAAAFxT24IJx0WvlGdawhuuzEWIQ1aD'
};
