import React, { ReactNode } from 'react';
import { RequestProvider, withPublicLink } from '@shootsta/common-react';
import { History } from 'history';
import SSOAuthComponent from '../components/SSOAuth';
import { getOrgSSOConfigApi } from '../api';
import { ActionRequest } from '../../../types';

interface SSOAuthProps {
  location: Location,
  history: History,
  children: ReactNode
}

function SSOAuth(props: SSOAuthProps) {
  return (

    <RequestProvider
      requests={{
        getOrgSSOConfig: getOrgSSOConfigApi()
      }}
    >
      {({ getOrgSSOConfig }: { getOrgSSOConfig: ActionRequest }) => (
        <SSOAuthComponent
          getOrgSSOConfig={getOrgSSOConfig}
          {...props}
        />
      )}
    </RequestProvider>
  );
}

export default withPublicLink(SSOAuth);
