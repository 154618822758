import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import LogoutComponent from '../components/Logout';
import { logoutApi } from '../../../api';
import { ActionRequest } from '../../../types';

type Props = {
  children: JSX.Element;
};

function Logout({ children }: Props): JSX.Element {
  if (window.location.pathname !== '/logout') {
    return children;
  }

  return (
    <RequestProvider requests={{ logout: logoutApi() }}>
      {(props: { logout: ActionRequest }) => (
        <LogoutComponent logout={props.logout} />
      )}
    </RequestProvider>
  );
}

export default Logout;
