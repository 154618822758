import { gql } from 'apollo-boost';
import { GraphQLLAuthorizationCodeLogin } from '../types';

const LOGIN_AUTHORIZATION_CODE_QUERY = gql`
    mutation ($authorizationCode: String!, $fingerprint: String) {
        authorizationCodeLogin(authorizationCode: $authorizationCode, fingerprint: $fingerprint) {
            auth
            token
            user {
                firstName
                lastName
                userId
                email
                imageUrl
                preferences
                isAvailable
                isAnonymous
                initialSetup
            }
        }
    }
`;

export default (): GraphQLLAuthorizationCodeLogin => ({
  mutation: LOGIN_AUTHORIZATION_CODE_QUERY,
  module: 'identity',
  buildVariables: (
    { authorizationCode, fingerprint }
  ) => ({ authorizationCode, fingerprint })
});
