import { gql } from 'apollo-boost';
import { GraphQLLWorkspaceLogin } from '../types';

const LOGIN_WORKSPACE_QUERY = gql`
    mutation ($alias: String!, $fingerprint: String) {
        workspaceLogin(alias: $alias, fingerprint: $fingerprint) {
            auth
            token
            user {
                firstName
                lastName
                userId
                email
                imageUrl
                preferences
                isAvailable
                isAnonymous
                initialSetup
            }
        }
    }
`;

export default (): GraphQLLWorkspaceLogin => ({
  mutation: LOGIN_WORKSPACE_QUERY,
  module: 'identity',
  buildVariables: (
    { alias, fingerprint }
  ) => ({ alias, fingerprint })
});
