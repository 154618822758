import React, { FC, createRef, useCallback } from 'react';
import MediaQuery from 'react-responsive';
import ReactCodeInput from 'react-verification-code-input';
import { formatDate } from '@shootsta/get-moment';
import { Link as UiLink, Icon, Text } from '@shootsta/common-react';
import LoginHeader from '../LoginHeader';

interface CodeFormProps {
  onComplete: (code: string) => Promise<boolean>;
  resetError: () => void;
  resendCode: () => Promise<void>;
  seconds: number;
  isCounterOn: boolean;
  error?: string;
  email?: string;
}

const CodeForm: FC<CodeFormProps> = ({
  onComplete,
  error,
  email = '',
  resetError,
  resendCode,
  seconds,
  isCounterOn
}) => {
  // it creates new ref on each render, should be checked could it be replaced by useRef
  const codeInputRef = createRef<ReactCodeInput>();

  const clearForm = useCallback(() => {
    if (codeInputRef && codeInputRef.current) {
      codeInputRef.current.__clearvalues__();
    }

    resetError();
  }, [codeInputRef, resetError]);

  const resend = useCallback(async () => {
    await resendCode();
    clearForm();
  }, [resendCode, clearForm]);

  return (
    <div className="login-form">
      <div className="login-form__body">
        <LoginHeader title="Check your email for a code" subtitle={`We've sent a six-digit code to ${email}`} />
        <Text>The code expires shortly, so please enter it soon</Text>
        <MediaQuery minWidth={640}>
          <ReactCodeInput
            className="login-form__code"
            autoFocus
            type="text"
            fieldHeight={80}
            fieldWidth={70}
            onComplete={onComplete}
            onChange={resetError}
            ref={codeInputRef}
          />
        </MediaQuery>
        <MediaQuery maxWidth={639}>
          <ReactCodeInput
            className="login-form__code"
            autoFocus
            type="text"
            fieldHeight={60}
            fieldWidth={40}
            onComplete={onComplete}
            onChange={resetError}
            ref={codeInputRef}
          />
        </MediaQuery>
        {error ? (
          <div className="login-form__body__error">
            <Icon name="alert" color="thunderBird" size={15} />
            <p>{error}</p>
          </div>
        ) : null}
        <div className="login-form__body__account">
          <div className="login-form__body__help-link">
            {isCounterOn ? (
              <>
                {'Can\'t find your code? Try to resend it in '}
                <span className="login-form__body__counter">
                  {formatDate(seconds * 1000, 'mm:ss')}
                </span>
              </>
            ) : (
              <>
                {'Can\'t find your code? Check your spam folder or '}
                <UiLink onClick={resend}>
                  resend code.
                </UiLink>
              </>
            )}
          </div>
        </div>
        <div className="login-form__footer" />
      </div>
    </div>
  );
};

export default CodeForm;
