import React, { Component, ReactNode } from 'react';
import { credentials } from '@shootsta/client-auth';
import { Error } from '@shootsta/common-react';
import queryString from 'query-string';
import _get from 'lodash.get';
import { History } from 'history';

import { redirectToSingleSignOn, setSSOLocalStore, getAlias as getOrgAlias } from '../../../../utils';
import { ActionRequest } from '../../../types';

type SSOAuthProps = {
  getOrgSSOConfig: ActionRequest,
  location: Location,
  history: History,
  children: ReactNode
};

type SSOAuthState = {
  shouldRenderChildren: boolean,
  error?: string
};

export default class SSOAuth extends Component<SSOAuthProps, SSOAuthState> {
  constructor(props: SSOAuthProps) {
    super(props);

    this.state = { shouldRenderChildren: false };
  }

  async componentDidMount(): Promise<void> {
    const { getOrgSSOConfig, location, history } = this.props;
    const orgAlias = getOrgAlias();

    const { data, error } = orgAlias
      ? await getOrgSSOConfig(orgAlias)
      : { data: null, error: null };

    if (error) {
      return void this.setState({ error });
    }

    const ssoEnabled = orgAlias
      ? _get(data, 'getOrganisationSSOConfig.ssoConfig.ssoEnabled')
      : false;

    const existingCredentials = credentials.get();
    const { ghost, auth } = existingCredentials || {};

    // Handling for a SSO user already authenticated, hard refreshing the page
    if (ssoEnabled && (auth && ghost === void 0)) {
      setSSOLocalStore({ ssoEnabled: true });
      return void this.setState({ shouldRenderChildren: true });
    }

    // We set a ghost value for ghosting to skip sso
    // If user is already authenticated (auth = true) skip sso
    // Should probably come up with a better solution to this
    if (!ssoEnabled || ghost || auth) {
      return void this.setState({ shouldRenderChildren: true });
    }

    const urlQuery = queryString.parse(location.search);

    // If sso query param is not present means a SSO user
    // is directly trying to use HUB login rather than their SSO login
    if (!urlQuery.sso) {
      return void redirectToSingleSignOn(location.pathname);
    }

    const decodedData = atob(String(urlQuery.sso));

    credentials.set(JSON.parse(decodedData));
    setSSOLocalStore({ ssoEnabled: true });

    history.push(location.pathname);

    this.setState({ shouldRenderChildren: true });
  }

  render(): ReactNode {
    const { shouldRenderChildren, error } = this.state;
    const { children } = this.props;

    if (error) { return <Error />; }

    if (!shouldRenderChildren) { return null; }

    return children;
  }
}
