import React, { Component, ReactNode } from 'react';
import { configureScope } from '@sentry/browser';
import _get from 'lodash.get';
import queryString from 'query-string';
import { credentials, authLocalStore } from '@shootsta/client-auth';
import { withPublicLink } from '@shootsta/common-react';

import When from '../../../components/When';
import WelcomeGuestModal from '../../../components/WelcomeGuest';
import config from '../../../../config';
import { redirectToSingleSignOn, getSSOLocalStore } from '../../../../utils';
import { ActionRequest, TokenData } from '../../../types';

type Props = {
  publicFallback?: JSX.Element,
  children?: JSX.Element,
  userIsRequired: boolean,
  refreshToken: ActionRequest<TokenData>,
  location: Location
};

type State = {
  hasToken: boolean,
  isReauthRequired: boolean,
  isRefreshing: boolean
};

function setUserContext(user: Record<string, unknown>) {
  if (config.sentryDSN) {
    configureScope((scope) => {
      scope.setUser(user);
    });
  }
}

class AuthProtected extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { auth, user, token } = credentials.get();

    setUserContext(user);

    this.state = {
      isRefreshing: true,
      hasToken: Boolean(token),
      isReauthRequired: Boolean(!auth && token)
    };

    credentials.listenToChange(this.handleCredentialsChange);
  }

  async componentDidMount() {
    const { hasToken, isReauthRequired } = this.state;
    const { refreshToken, location } = this.props;
    const { auth, user, original = null, origURL = null, ghost } = credentials.get();
    const { ssoEnabled } = getSSOLocalStore();

    if (isReauthRequired && ssoEnabled) {
      credentials.clear();

      this.setState({ isRefreshing: false });

      return void redirectToSingleSignOn(location.pathname);
    }

    if (!hasToken || !auth || !user || ssoEnabled) {
      this.setState({ isRefreshing: false });

      return;
    }

    try {
      const { data, error } = await refreshToken();

      if (error || !_get(data, 'refreshToken.auth')) {
        credentials.clear();

        this.setState({ isRefreshing: false });

        return;
      }

      const { refreshToken: refreshTokenData } = data as TokenData;

      const resultWithOriginal = {
        data: {
          refreshToken: {
            ...refreshTokenData,
            original,
            origURL,
            ghost
          }
        }
      };

      authLocalStore.save(resultWithOriginal);
    } catch {
      // Silently handle errors and maintain existing credentials.
    } finally {
      this.setState({ isRefreshing: false });
    }
  }

  handleCredentialsChange = (usercrdls?: { auth?: boolean, token?: string }) => {
    if (!usercrdls) {
      return void this.setState({ hasToken: false, isReauthRequired: false });
    }

    const { auth, token } = usercrdls;

    const isReauthRequired = Boolean(!auth && token);

    this.setState({
      hasToken: Boolean(token),
      isReauthRequired
    }, () => {
      const { location } = this.props;
      const { ssoEnabled } = getSSOLocalStore();

      if (isReauthRequired) {
        credentials.clear();
      }

      if (!isReauthRequired || !ssoEnabled) { return; }

      redirectToSingleSignOn(location.pathname);
    });
  };

  render(): ReactNode {
    const {
      publicFallback,
      children,
      userIsRequired,
      location
    } = this.props;
    const { hasToken, isRefreshing } = this.state;

    if (isRefreshing) {
      return null;
    }

    const { redirecting } = queryString.parse(location.search);

    if (redirecting) { return null; }

    if (userIsRequired) {
      return (
        <WelcomeGuestModal />
      );
    }

    return (
      <When condition={hasToken} failWith={publicFallback}>
        {children}
      </When>
    );
  }
}

export default withPublicLink(AuthProtected);
