import React, { Component, ReactNode } from 'react';
import { History } from 'history';
import { Icon, SetPasswordForm } from '@shootsta/common-react';
import { credentials, authLocalStore } from '@shootsta/client-auth';
import {
  withGoogleReCaptcha,
  IWithGoogleReCaptchaProps
} from 'react-google-recaptcha-v3';

import { login } from '../../../utils';
import { RETURN_TO } from '../../../common/constants';
import { ActionRequest, LoginData, PasswordData } from '../../../common/types';

import '../styles/_reset-password.scss';

const RESET_TOKEN = 't';

function extractUrlParam(history: History, key: string): string {
  return new URLSearchParams(history.location.search).get(key) || '';
}

interface ResetPasswordState {
  submitting: boolean,
  error?: string,
}

interface ResetPasswordProps {
  changePassword: ActionRequest<PasswordData>,
  loginRequest: ActionRequest<LoginData>,
  history: History
}

type WithHocProps = ResetPasswordProps & Partial<IWithGoogleReCaptchaProps>;

class ResetPassword extends Component<WithHocProps, ResetPasswordState> {
  constructor(props: Readonly<WithHocProps>) {
    super(props);

    this.state = {
      submitting: false
    };
  }

  onSubmit = async (password: string): Promise<void> => {
    const {
      loginRequest,
      changePassword,
      history,
      googleReCaptchaProps: { executeRecaptcha } = {}
    } = this.props;
    const tempToken = extractUrlParam(history, RESET_TOKEN);
    const returnToWithoutProtocol = extractUrlParam(history, RETURN_TO);
    const redirectLink = returnToWithoutProtocol ? `https://${returnToWithoutProtocol}` : null;

    this.setState({ submitting: true });

    const originalCredentials = credentials.get();
    const { user: userObj } = originalCredentials;

    if (tempToken) {
      authLocalStore.save({
        ...originalCredentials,
        user: { ...userObj, memberships: [] },
        token: tempToken
      });
    }

    const { data, error } = await changePassword(password);

    if (error || !data || !data.changePassword) {
      this.setState({ submitting: false });

      return;
    }

    const { email } = data.changePassword;

    this.setState({ submitting: true });

    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Google recaptcha not yet available');
      return;
    }

    const recaptchaToken = await executeRecaptcha('login');

    const result = await login(loginRequest, { email, password, recaptchaToken });

    if (!result) {
      this.setState({ submitting: false });

      if (redirectLink) {
        window.location.href = redirectLink;
      } else {
        history.push('/');
      }

      return;
    }

    this.setState(result);
  };

  render(): ReactNode {
    const { submitting } = this.state;

    return (
      <div className="reset-password">
        <div className="reset-password__header">
          <Icon name="shootstacolour" size={35} />
          <h1>Reset your Password</h1>
          <h2>Make sure it’s unique</h2>
        </div>
        <SetPasswordForm
          onSubmit={this.onSubmit}
          buttonText="Save Changes"
          busy={submitting}
        />
      </div>
    );
  }
}

export default withGoogleReCaptcha(ResetPassword);
