import config from '../config';
import getOrgAlias from './getWorkspaceAlias';

export function redirectToSingleSignOn(path: string): void {
  window.location.href = `${String(config.ssoApiEndpoint)}?al=${getOrgAlias()}&url=${path}`;
}

export function redirectToSingleLogout(): void {
  window.location.href = `${String(config.ssoApiEndpoint)}logout?al=${getOrgAlias()}`;
}
