import React, { Component, ReactNode } from 'react';
import { History } from 'history';
import queryString from 'query-string';
import globalConfig from 'react-global-configuration';
import isEmail from 'validator/lib/isEmail';
import { credentials } from '@shootsta/client-auth';
import { Button, Input } from '@shootsta/common-react';
import LoginHeader from './LoginHeader';
import { onEnterPressed } from '../utils';
import { RETURN_TO } from '../../../constants';
import { ForgotPasswordFunction } from '../../../types';
import '../styles/_forgot-password.scss';

type Props = {
  history: History;
  forgotPassword: ForgotPasswordFunction;
};

type State = {
  userEmail: string;
  emailSent: boolean;
  submitting: boolean;
};

export default class ForgotPassword extends Component<Props, State> {
  isLoggedInUser: boolean;

  constructor(props: Props) {
    super(props);

    const { user, auth, token } = credentials.get();

    const { email = '' } = user || {};

    this.state = {
      userEmail: email,
      emailSent: false,
      submitting: false
    };

    this.isLoggedInUser = Boolean(auth) && Boolean(token);
  }

  onUpdateEmailField = (
    { target: { value } }: { target: { value: string; }; }
  ): void => {
    if (!this.isLoggedInUser) {
      credentials.set({ user: { email: value } });
    }

    this.setState({
      userEmail: value
    });
  };

  goToLogin = (): void => {
    const { history } = this.props;

    history.push('/');
  };

  onSubmit = async (): Promise<void> => {
    const { forgotPassword, history } = this.props;
    const { userEmail: email } = this.state;

    const { location: { search } } = history;

    const { [RETURN_TO]: returnTo } = queryString.parse(search);
    const returnToMinusProtocol = (
      returnTo && typeof returnTo === 'string'
      && returnTo.replace(/^https?:\/\//, '')
    ) || void 0;
    this.setState({ submitting: true });
    await forgotPassword({
      email,
      identityEndpoint: globalConfig.get('identityEndpoint'),
      returnTo: returnToMinusProtocol
    });

    this.setState({
      submitting: false,
      emailSent: true
    });
  };

  canSubmit = (email: string): boolean => !!(email && isEmail(email));

  isEmailValid = (value: string): boolean => !value || isEmail(value);

  render(): ReactNode {
    const { emailSent, submitting, userEmail: email } = this.state;

    const buttonText = this.isLoggedInUser
      ? 'View Workspaces'
      : 'Back To Login';

    return (
      <div className="forgot-password">
        {!emailSent ? (
          <>
            <div className="forgot-password__container">
              <LoginHeader
                title="Let's get you back up and running"
                subtitle="Enter your email below and we'll send you a reset link"
              />
              <Input
                autoFocus
                data-cy="login-email-input"
                data-testid="login-email-input"
                id="email-input"
                className="forgot-password__field"
                placeholder="johnsmith@email.com  "
                onChange={this.onUpdateEmailField}
                onKeyDown={onEnterPressed({
                  onClick: this.onSubmit
                })}
                prefix="email"
                label="Email Address"
                defaultValue={email}
                validate={this.isEmailValid}
                errorMessage="That doesn't look like an email address."
              />
              <Button
                busy={submitting}
                disabled={!this.canSubmit(email)}
                data-cy="reset-password-submit"
                className="forgot-password__submit"
                onClick={this.onSubmit}
              >
                Reset Password
              </Button>
            </div>
          </>
        ) : (
          <div className="forgot-password__container">
            <LoginHeader
              title="Request Submitted"
              subtitle="If you have a Shootsta account, an email containing a password reset link will be sent to you."
              hideIcon
            />
            <Button
              data-cy="back-to-login-submit"
              className="forgot-password__submit"
              onClick={this.goToLogin}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
