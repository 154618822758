import React, { Component, ReactNode } from 'react';
import { Loader, Error } from '@shootsta/common-react';
import { RETURN_TO, AUTHORIZATION_CODE, AUTH_ROUTE, GHOST, TTL } from '../../../constants';
import config from '../../../../config';
import { GenerateAuthorizationCode } from '../../../types';
import isNumber from '../utils/isNumber';

const SHOOTSTA_ORIGIN_REGEX = /^https:\/\/.*(shootsta.com|shootsta.dev|hub3client-core-mono.vercel.app)$/g;
const LOCALHOST_ORIGIN_REGEX = /^http:\/\/.*(localhost:)\d*$/g;

interface AuthRedirectProps {
  generateAuthorizationCode: GenerateAuthorizationCode
}

interface AuthRedirectState {
  error: boolean
}

export default class AuthRedirect extends Component<AuthRedirectProps, AuthRedirectState> {
  constructor(props: AuthRedirectProps) {
    super(props);

    if (!this.getReturnTo()) {
      this.defaultRedirectUser();
    }

    this.state = {
      error: false
    };
  }

  componentDidMount(): Promise<void> {
    return this.authenticateWorkspace();
  }

  getRedirectTTL(): number | null {
    const redirectTTL = this.getURLParams().get(TTL);

    if (!redirectTTL) {
      return null;
    }

    const parsedRedirectTTL = Number.parseInt(redirectTTL, 10);

    return isNumber(parsedRedirectTTL) ? parsedRedirectTTL : null;
  }

  getNextRedirectTTL(): string {
    const redirectTTL = this.getRedirectTTL();

    if (redirectTTL === null) {
      return '0';
    }

    return Math.max(redirectTTL - 1, 0).toString();
  }

  getReturnTo(): string | null {
    const returnToMinusProtocol = this.getURLParams().get(RETURN_TO);
    return returnToMinusProtocol ? `${window.location.protocol}//${returnToMinusProtocol}` : null;
  }

  getTargetOrigin(): string | null {
    return new URL('', this.getReturnTo() || config.defaultReturnToSuccessEndpoint).origin;
  }

  getGhostToken(): string | null {
    return this.getURLParams().get(GHOST) || null;
  }

  defaultRedirectUser = (): void => {
    window.location.href = config.defaultReturnToSuccessEndpoint;
  };

  getURLParams = (): URLSearchParams => new URLSearchParams(window.location.search || '');

  getAuthorizationCode = async (): Promise<string> => {
    const { generateAuthorizationCode } = this.props;

    const { data, error } = await generateAuthorizationCode();

    if (!data || !data.generateAuthorizationCode || error) {
      return '';
    }

    return data.generateAuthorizationCode;
  };

  authenticateWorkspace = async (): Promise<void> => {
    const targetOrigin = this.getTargetOrigin();
    const returnTo = this.getReturnTo();

    if (!targetOrigin || !returnTo) {
      return void this.setState({ error: true });
    }

    if (!targetOrigin.match(SHOOTSTA_ORIGIN_REGEX) && !targetOrigin.match(LOCALHOST_ORIGIN_REGEX)) {
      return void this.setState({ error: true });
    }

    const authorisationCode = await this.getAuthorizationCode();

    if (!authorisationCode) {
      return void this.setState({ error: true });
    }

    const ghost = this.getGhostToken();
    const returnToMinusProtocol = returnTo.replace(/^https?:\/\//, '');
    const searchParams = new URLSearchParams({
      [AUTHORIZATION_CODE]: authorisationCode,
      [RETURN_TO]: returnToMinusProtocol,
      [TTL]: this.getNextRedirectTTL(),
      ...(ghost ? { [GHOST]: ghost } : {})
    });

    window.location.href = `${targetOrigin}/${AUTH_ROUTE}?${searchParams.toString()}`;
  };

  render(): ReactNode {
    const { error } = this.state;
    const src = this.getReturnTo();

    if (!src) {
      return <></>;
    }

    if (error) {
      return (
        <Error />
      );
    }

    return (
      <Loader />
    );
  }
}
