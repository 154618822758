import React, { memo, ComponentType } from 'react';
import { Switch, Route } from 'react-router-dom';
import { History } from 'history';
import Background from './Background';
import LoginForm from './LoginForm';
import '../styles/_login.scss';
import { ActionRequest, LoginData } from '../../../types';

type Props = {
  loginRequest: ActionRequest<LoginData>,
  history: History
};

function renderLogin(history: History, loginRequest: ActionRequest<LoginData>): ComponentType {
  return () => <LoginForm history={history} loginRequest={loginRequest} />;
}

const Login = ({ loginRequest, history }: Props) => (
  <div className="login">
    <div className="login__container" data-cy="login-container">
      <div className="login__left-content">
        <Switch>
          <Route component={renderLogin(history, loginRequest)} />
        </Switch>
      </div>
      <Switch>
        <Route path="/login/forgot">
          <Background type="auth" />
        </Route>
        <Route>
          <Background type="create" />
        </Route>
      </Switch>
    </div>
  </div>
);

export default memo<Props>(Login);
