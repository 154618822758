import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

type AppContainerProps = {
  children: ReactNode
};

export default function AppContainer({ children }: AppContainerProps): JSX.Element {
  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  );
}
