import React, { FC } from 'react';
import { Icon, Text, Link as UiLink } from '@shootsta/common-react';
import LoginHeader from '../LoginHeader';
import ChooseWorkspaceListItem from './ChooseWorkspaceListItem';
import { UserOrganisation } from '../../types/GetOrgsForUserCode';

import '../../styles/_login.scss';
import '../../styles/_choose-workspaces.scss';

interface ChooseWorkspaceListProps {
  organisations: Array<UserOrganisation>;
  email?: string;
  useAnotherAddress?: () => void;
}

const ChooseWorkspaceList: FC<ChooseWorkspaceListProps> = ({
  organisations = [],
  email,
  useAnotherAddress }) => (
    <div className="login-form">
      <div className="login-form__body">
        {
            email ? (
              <div data-testid="user-logged-block" className="login-form__body__success">
                <Icon name="success" color="monkey" size={20} />
                <div className="success-text">
                  <Text>
                    Confirmed as {email}
                  </Text>
                  <UiLink onClick={useAnotherAddress}>Use another address</UiLink>
                </div>
              </div>
            ) : null
          }
        <LoginHeader
          title="Your workspaces"
          subtitle="You're a member of the following workspaces"
        />
        <div className="workspaces-items">
          {
            organisations.length
              ? organisations.map(({ name, alias, imageUrl, organisationId }) => (
                <ChooseWorkspaceListItem
                  key={organisationId}
                  name={name}
                  alias={alias}
                  imageUrl={imageUrl}
                />
              )) : 'Not currently a member of any workspace'
            }
        </div>
        <div data-testid="footer-block" className="workspaces-items-help-info">
          <Text>Looking for a different workspace? You can </Text>
          <UiLink onClick={useAnotherAddress}>try another email address</UiLink>
          <br />
          <Text> or ask your Workspace Administrator for an invitation</Text>
        </div>
      </div>
    </div>
);

export default ChooseWorkspaceList;
