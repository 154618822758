import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Error } from '@shootsta/common-react';
import { withScope, captureException, showReportDialog } from '@sentry/browser';

type ErrorBoundaryState = {
  error?: Error
};

type ErrorBoundaryProps = {
  children: ReactNode
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });

    withScope((scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack);

      captureException(error);
    });
  }

  render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (!error) { return children; }

    return <Error onSubmitError={showReportDialog} />;
  }
}
