import React, { Component, ReactNode } from 'react';
import MediaQuery from 'react-responsive';
import isEmail from 'validator/lib/isEmail';
import { History } from 'history';
import { credentials } from '@shootsta/client-auth';
import { AuthButtons, Button, Input, Icon, Link, NUMERIC_MEDIA_SIZES } from '@shootsta/common-react';
import queryString from 'query-string';
import {
  withGoogleReCaptcha,
  IWithGoogleReCaptchaProps
} from 'react-google-recaptcha-v3';

import LoginHeader from './LoginHeader';
import { onEnterPressed, openHubSpotConversationsWidget } from '../utils';
import { login } from '../../../../utils';
import { ActionRequest, LoginData } from '../../../types';
import { LOGIN_SOCIAL_DATA_KEY } from '../constants/loginSocial';
import { CustomAny } from '../../../types/CustomAny';
import { ALIAS } from '../../../constants';
import '../styles/_login-form.scss';

type Props = {
  loginRequest: ActionRequest<LoginData>,
  history: History,
};

type WithHocProps = Props & Partial<IWithGoogleReCaptchaProps>;

type State = {
  password: string,
  error: string,
  submitting: boolean,
  alias?: string
};

class LoginForm extends Component<WithHocProps, State> {
  constructor(props: WithHocProps) {
    super(props);

    const {
      [ALIAS]: alias
    } = queryString.parse(window.location.search) as {
      [ALIAS]: string
    };

    this.state = {
      password: '',
      error: '',
      submitting: false,
      alias
    };
  }

  /**
   * Updates a field in state
   *
   * @param {string} fieldName
   *
   * @return {Function}
   *
   */
  onUpdateField = (fieldName: string) => (
    { target: { value } }: { target: { value: string } }
  ): void => {
    this.setState({ [fieldName]: value, error: '' } as Pick<State, 'password' | 'error'>);
  };

  onUpdateEmailField = (
    { target: { value } }: { target: { value: string; }; }
  ): void => {
    credentials.set({ user: { email: value } });

    // Manually trigger re-render, so login button can go green/grey
    this.setState({});
  };

  onSubmit = async (): Promise<void> => {
    const { loginRequest } = this.props;
    const { password } = this.state;
    const { user: { email } } = credentials.get();
    if (!this.canSubmit(email, password)) { return; }

    this.setState({ error: '', submitting: true });

    await this.submit(loginRequest, { email, password });
  };

  onSuccessSocial = async (data: CustomAny): Promise<void> => {
    await this.submit(
      async () => ({ data: { [LOGIN_SOCIAL_DATA_KEY]: data.userAuthData } } as CustomAny),
      {},
      { dataKey: LOGIN_SOCIAL_DATA_KEY }
    );
  };

  submit = async (
    loginReq: ActionRequest<LoginData>,
    payload: Record<string, unknown>,
    options?: { dataKey: string }
  ): Promise<void> => {
    const { alias } = this.state;
    const { googleReCaptchaProps: { executeRecaptcha } = {} } = this.props;

    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Google recaptcha not yet available');
      return;
    }

    const recaptchaToken = await executeRecaptcha('login');

    const result = await login(loginReq, { ...payload, recaptchaToken, alias }, options);

    if (!result) { return; }

    this.setState(result);
  };

  canSubmit = (email: string, password: string): boolean => !!(email && password && isEmail(email));

  validationFunction = (value: string): boolean => !value || isEmail(value);

  goToForgotPassword = (): void => {
    const { history: { location: { search }, push } } = this.props;

    push(`/login/forgot${search}`);
  };

  render(): ReactNode {
    const {
      password,
      submitting,
      alias,
      error
    } = this.state;
    const { email = '' } = credentials.get().user || {};

    return (
      <div className="login-form">
        <div className="login-form__body">
          <LoginHeader
            title="Sign in to Shootsta"
            subtitle="Video simplified."
          />
          <form className="login-form__body__form">
            <Input
              autoFocus
              data-cy="login-email-input"
              data-testid="login-email-input"
              id="email-input"
              className="login-form__body__field"
              placeholder="Enter Email"
              defaultValue={email}
              onChange={this.onUpdateEmailField}
              prefix="email"
              label="Email Address"
              autoComplete="username"
              validate={this.validationFunction}
              errorMessage="That doesn't look like an email address."
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
            />
            <Input
              data-cy="login-password-input"
              data-testid="login-password-input"
              id="password-input"
              className="login-form__body__field"
              placeholder="Enter Password"
              value={password}
              type="password"
              onChange={this.onUpdateField('password')}
              prefix="padlock"
              autoComplete="current-password"
              label="Password"
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
            />

            {/* eslint-disable-next-line */}
            <Link
              className="login-form__body__forgot-btn"
              onClick={this.goToForgotPassword}
              button
            >
              Forgot Password?
            </Link>

            <AuthButtons
              submitBtn={(
                <Button
                  data-testid="login-submit"
                  data-cy="login-submit"
                  className="login-form__body__submit"
                  disabled={!this.canSubmit(email, password)}
                  onClick={this.onSubmit}
                  busy={submitting}
                >
                  Login
                </Button>
              )}
              socialProps={{
                alias,
                onSuccess: this.onSuccessSocial
              }}
            />
          </form>

          <p className="login-form__body__account">
            {'Don\'t have an account yet? Contact your team administrator for an invitation.'}
          </p>
          <div className="login-form__body__help-link" style={{ fontSize: '14px', marginTop: '-10px' }}>
            Having trouble signing in? Hit the &apos;
            <button
              type="button"
              id="live_chat_link"
              className="link link--button text--link"
              onClick={openHubSpotConversationsWidget}
            >
              Live Chat
            </button>
            &apos; button to get help right away
          </div>
          {error
            ? (
              <div className="login-form__body__error">
                <MediaQuery maxWidth={NUMERIC_MEDIA_SIZES.small}>
                  <Icon name="alert" color="black" size={15} />
                </MediaQuery>
                <MediaQuery minWidth={NUMERIC_MEDIA_SIZES.small + 1}>
                  <Icon name="alert" color="thunderBird" size={15} />
                </MediaQuery>
                <p data-testid="error-message">{error}</p>
              </div>
            ) : null}
        </div>
      </div>
    );
  }
}

export default withGoogleReCaptcha(LoginForm);
