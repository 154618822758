import React from 'react';
import { Input, Button, Text, Link } from '@shootsta/common-react';

import '../styles/_welcome-guest.scss';
import '../styles/_anonymous-commenter-modal.scss';

type AnonymousCommenterFormProps = {
  displayLoginForm: () => void,
  handleAnonymousUser: () => Promise<void>,
  updateName: ({ target: { value } }: { target: { value: string; }; }) => void,
  busy: boolean,
  disabled: boolean,
  error?: string
};

const PRIVACY_POLICY_URL = 'https://shootsta.com/privacy';

function openPrivacyPolicyPage() {
  window.open(PRIVACY_POLICY_URL, '_blank');
}

export default function AnonymousCommenterForm({
  displayLoginForm,
  handleAnonymousUser,
  updateName,
  busy,
  error,
  disabled
}: AnonymousCommenterFormProps): JSX.Element {
  return (
    <>
      <div className="anonymous-commenter-modal__body">
        <div className="anonymous-commenter-modal__body__input-container">
          <Input
            labelProps={{ required: true }}
            label="Name"
            className="anonymous-commenter-modal__input"
            onChange={updateName}
            placeholder="Enter name"
          />
        </div>

        <Button disabled={disabled} className="anonymous-commenter-modal__button" onClick={handleAnonymousUser} busy={busy}>
          Start Commenting
        </Button>

        <p className="anonymous-commenter-modal__or-text">or</p>

        <Button type="SECONDARY" className="anonymous-commenter-modal__button" onClick={displayLoginForm}>
          Log Back In
        </Button>
      </div>
      <div className="welcome-guest__footer">
        <Text label>
          By completing this action you agree to Shootsta’s
          {/* eslint-disable-next-line */}
          <Link
            className="welcome-guest__footer__link"
            onClick={openPrivacyPolicyPage}
          >
            Privacy Policy
          </Link>
          .
        </Text>
      </div>
      {error
        ? <Text danger prefixIcon="alert" prefixIconColor="redApple">Something went wrong</Text>
        : null}
    </>
  );
}
