import { gql } from 'apollo-boost';
import { GraphQlGhost } from '../types';

const GHOST_START_QUERY = gql`
    query GhostStart($email: String!) {
        ghostStart(email: $email) {
            token
            auth
            user {
                email
                userId
                firstName
                lastName
                preferences
                imageUrl
                isAvailable
            }
        }
    }
`;

export default (): GraphQlGhost => ({
  query: GHOST_START_QUERY,
  module: 'identity',
  buildVariables: ({ email }: { email: string }) => ({ email })
});
