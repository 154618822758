import store from 'store';

type SSOConfig = {
  ssoEnabled: boolean
};

const SSO_CONFIG_KEY = 'ssoConfig';

export function set(config: SSOConfig): void {
  store.set(SSO_CONFIG_KEY, config);
}

export function get(): SSOConfig {
  return store.get(SSO_CONFIG_KEY) || {};
}

export function clear(): void {
  store.remove(SSO_CONFIG_KEY);
}
