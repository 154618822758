import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  AuthorisationConsumer,
  Error,
  NotificationsProvider
} from '@shootsta/common-react';
import { ApolloConsumer } from 'react-apollo';

// Containers & Layouts
import { AuthProtected, ErrorBoundary, SSOAuth } from '../common/containers';

// App Pages
import AuthRedirect from '../common/components/AuthRedirect';
import Login from '../common/components/Login';
import Logout from '../common/components/Logout';
import ResetPassword from './ResetPassword';
import Workspace from '../common/components/Login/containers/Workspace';
import Verification from '../common/components/Login/containers/Verification';
import ChooseWorkspace from '../common/components/Login/containers/ChooseWorkspace';
import WelcomeGuest from '../common/components/WelcomeGuest';
import ForgotPassword from '../common/components/Login/containers/ForgotPassword';
import { getAlias } from '../utils';
import AuthorizationCodeLogin from './AuthorizationCodeLogin';
import { RoutePath } from '../common/enums/RoutePath';

function renderError() {
  return () => <Error />;
}

/**
 * Creates the application route components
 *
 * @return {Element}
 */

function aliasRoutes() {
  return (
    <>
      <SSOAuth>
        <AuthProtected
          publicFallback={<Login />}
        >
          <AuthorisationConsumer>
            {() => (
              <AuthRedirect />
            )}
          </AuthorisationConsumer>
        </AuthProtected>
      </SSOAuth>
    </>
  );
}

function withoutAliasRoutes() {
  return (
    <Switch>
      <Route path={RoutePath.GetWorkspaces}>
        <Verification />
      </Route>

      <Route path={RoutePath.Code} exact>
        <AuthorizationCodeLogin />
      </Route>

      <AuthProtected
        publicFallback={<Workspace />}
      >
        <Route>
          <ChooseWorkspace />
        </Route>
      </AuthProtected>
    </Switch>
  );
}

export default function createRoutes(): JSX.Element {
  const alias = getAlias();

  return (
    <ErrorBoundary>
      <Logout>
        <NotificationsProvider>
          <ApolloConsumer>
            {() => (
              <Switch>
                <Route path="/error" render={renderError} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/guest" component={WelcomeGuest} />
                <Route path="/login/forgot" component={ForgotPassword} />
                {alias ? aliasRoutes() : withoutAliasRoutes()}
              </Switch>
            )}
          </ApolloConsumer>
        </NotificationsProvider>
      </Logout>
    </ErrorBoundary>
  );
}
