import { gql } from 'apollo-boost';
import { GraphQLGenerateVerification } from '../types';

const GENERATE_VERIFICATION_CODE_QUERY = gql`
    mutation generateVerificationCode($email: String!) {
        generateVerificationCode(email: $email)
    }
`;

export default (():GraphQLGenerateVerification => ({
  mutation: GENERATE_VERIFICATION_CODE_QUERY,
  module: 'identity',
  buildVariables: ({ email }) => ({
    email
  })
}));
