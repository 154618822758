import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import queryString from 'query-string';
import { credentials } from '@shootsta/client-auth';

import WelcomeModalComponent from '../components/WelcomeGuestModal';
import { loginAnonymousUserApi } from '../api';
import { ActionRequest, LoginData } from '../../../types';
import { RETURN_TO } from '../../../constants';

interface Props {
  history: History
}

function WelcomeGuest(props: Props): JSX.Element {
  const { auth, token } = credentials.get();

  const { history } = props;
  const { location: { search } } = history;

  if (auth && token) {
    history.push(`/${search}`);

    return <></>;
  }

  const { [RETURN_TO]: returnTo } = queryString.parse(search);

  const publiclyVisibleRoutes = ['/pro/complete', '/cast/complete'];

  const isAnonymousCommenter = returnTo && publiclyVisibleRoutes.find((r) => returnTo.includes(r));

  return (
    <RequestProvider
      requests={{
        loginAnonymousUserRequest: loginAnonymousUserApi()
      }}
    >
      {({ loginAnonymousUserRequest }: {
        loginAnonymousUserRequest: ActionRequest<LoginData>
      }) => (
        <WelcomeModalComponent
          loginAnonymousUserRequest={loginAnonymousUserRequest}
          isAnonymousCommenter={!!isAnonymousCommenter}
          {...props}
        />
      )}
    </RequestProvider>
  );
}

export default withRouter(WelcomeGuest);
