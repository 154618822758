import React, { FC, useCallback, useMemo } from 'react';
import { Button, Icon, Image, Text } from '@shootsta/common-react';

import { getWorkspaceUrl } from '../../../../../utils';
import { UserOrganisation } from '../../types/GetUserOrganisations';

type ChooseWorkspaceListItemProps = Pick<UserOrganisation, 'name' | 'alias' | 'imageUrl'>;

const ChooseWorkspaceListItem: FC<ChooseWorkspaceListItemProps> = ({
  name,
  alias,
  imageUrl
}) => {
  const goToWorkspace = useCallback(() => {
    const workspaceUrl = getWorkspaceUrl(alias);
    window.open(workspaceUrl);
  }, [alias]);
  const label = useMemo(() => (new URL(getWorkspaceUrl(alias)).hostname), []);

  return (
    <div className="workspaces-item">
      <div className="workspaces-item__info">
        <Image
          key="selectable-item-image"
          imageUrl={imageUrl}
          width="60px"
          height="60px"
          defaultIconBackground="parthenon"
          backgroundSize="cover"
          isEditableImage={false}
        />
        <div className="workspaces-item__info-name">
          <Text heading4 noMargin>{name}</Text>
          <Text ellipsisOverflow subtitle noMargin>
            {label}
          </Text>
        </div>
      </div>
      <Button
        className="launch"
        type="TERTIARY"
        onClick={goToWorkspace}
      >
        Launch
      </Button>
      <Icon
        className="return"
        color="monkey"
        name="returnI"
        onClick={goToWorkspace}
        size={25}
      />
    </div>
  );
};

export default ChooseWorkspaceListItem;
