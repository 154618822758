import React, { memo, FC, PropsWithChildren } from 'react';
import Background from '../Background';
import '../../styles/_login.scss';

type WorkspaceWrapperProps = {
  backgroundType: 'person' | 'lost' | 'create';
};

const WorkspaceWrapper: FC<WorkspaceWrapperProps> = ({ children, backgroundType }) => (
  <div className="login">
    <div className="login__container" data-cy="workspace-container">
      <div className="login__left-content">
        <div>
          {children}
        </div>
      </div>
      <Background type={backgroundType} />
    </div>
  </div>
);

export default memo<PropsWithChildren<WorkspaceWrapperProps>>(WorkspaceWrapper);
