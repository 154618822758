import { gql } from 'apollo-boost';

const LOGOUT_QUERY = gql`
    query logout {
        logout
    }
`;

export default (): unknown => ({
  query: LOGOUT_QUERY,
  module: 'identity',
  ignoreCache: true,
  buildVariables: () => {}
});
