import React, { ChangeEvent, useCallback, useMemo, FC, KeyboardEventHandler } from 'react';
import { AuthButtons, Button, Icon, Input } from '@shootsta/common-react';
import isEmail from 'validator/lib/isEmail';
import LoginHeader from '../LoginHeader';
import { onEnterPressed } from '../../utils';
import { CustomAny } from '../../../../types/CustomAny';

interface AuthTypeFormProps {
  onEmailFormSubmit: () => Promise<void>;
  canSubmitEmail: (email?: string) => boolean;
  submitting?: boolean;
  onEmailChange: (value: string) => void;
  onSuccessSocial: (data: CustomAny) => void;
  email?: string;
  error?: string;
}

function validateEmail(value: string): boolean {
  return !value || isEmail(value);
}

const AuthTypeForm: FC<AuthTypeFormProps> = ({
  onEmailFormSubmit,
  canSubmitEmail,
  submitting,
  onEmailChange,
  onSuccessSocial,
  email,
  error
}) => {
  const socialProps = useMemo(() => ({
    onSuccess: onSuccessSocial
  }), [onSuccessSocial]);

  const handleEmailChange = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    ({ target: { value } }) => onEmailChange(value),
  [onEmailChange]);

  const handleKeyDown = useMemo<KeyboardEventHandler | undefined>(
    () => onEnterPressed({ onClick: onEmailFormSubmit }),
    [onEmailFormSubmit]);

  return (
    <div className="login-form">
      <div className="login-form__body">
        <LoginHeader
          title="Let's find your space"
          subtitle="Start by choosing the email you use to login"
        />
        {error && (
          <div className="login-form__body__error">
            <Icon name="alert" color="thunderBird" size={15} />
            <p>{error}</p>
          </div>
        ) }
        <form className="login-form__body__form">
          <Input
            data-cy="workspace-email-input"
            id="email-input"
            className="login-form__body__field"
            placeholder="name@company.com"
            value={email}
            onChange={handleEmailChange}
            prefix="email"
            label="Email Address"
            autoComplete="username"
            validate={validateEmail}
            errorMessage="That doesn't look like an email address."
            onKeyDown={handleKeyDown}
          />

          <AuthButtons
            submitBtn={(
              <Button
                data-cy="find-workspace-submit"
                className="login-form__body__submit"
                disabled={!canSubmitEmail(email)}
                onClick={onEmailFormSubmit}
                busy={submitting}
              >
                Continue
              </Button>
            )}
            socialProps={socialProps}
          />
        </form>
      </div>
    </div>
  );
};

export default AuthTypeForm;
