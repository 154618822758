export default function getFirstAndLastNameByName(name: string): {
  firstName: string;
  lastName: string;
} {
  const [firstName, ...rest] = name
    .trim()
    .split(' ');

  return {
    firstName,
    lastName: rest.join(' ')
  };
}
