import { KeyboardEvent, KeyboardEventHandler } from 'react';

interface OnEnterPressedArg {
  onClick?: KeyboardEventHandler;
}
type OnEnterPressedFunc = (arg: OnEnterPressedArg) => KeyboardEventHandler | undefined;

const onEnterPressed: OnEnterPressedFunc = ({ onClick }) => {
  if (!onClick) {
    return undefined;
  }

  // eslint-disable-next-line consistent-return
  return (event: KeyboardEvent) => {
    // Keycode is deprecated in newer browsers but some old ones still use it
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };
};

export default onEnterPressed;
