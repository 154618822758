import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { History } from 'history';
import AuthorizationCodeLoginComponent from '../components/AuthorizationCodeLogin';
import { authorizationCodeLoginApi } from '../../../common/api';
import { AuthorizationCodeLoginRequest } from '../../../common/types';

interface AuthorizationCodeLoginProps {
  history: History
}

interface Requests {
  authorizationCodeLogin: AuthorizationCodeLoginRequest;
}

const AuthorizationCodeLogin = ({ history }: AuthorizationCodeLoginProps): JSX.Element => (
  <ApolloConsumer>
    {() => (
      <RequestProvider requests={{
        authorizationCodeLogin: authorizationCodeLoginApi()
      }}
      >
        {(requests: Requests) => (
          <AuthorizationCodeLoginComponent
            history={history}
            {...requests}
          />
        )}
      </RequestProvider>
    )}
  </ApolloConsumer>
);

export default withRouter(AuthorizationCodeLogin);
