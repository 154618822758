import React, { FC } from 'react';
import { RequestProvider } from '@shootsta/common-react';
import AuthRedirectComponent from '../components/AuthRedirect';
import { ghostStartApi, generateAuthorizationCodeApi } from '../../../api';
import { GhostStart, GenerateAuthorizationCode } from '../../../types';

interface Requests {
  generateAuthorizationCode: GenerateAuthorizationCode,
  ghostStart: GhostStart
}

const AuthRedirect: FC = () => (
  <RequestProvider requests={{
    generateAuthorizationCode: generateAuthorizationCodeApi(),
    ghostStart: ghostStartApi()
  }}
  >
    {(requests: Requests) => (
      <AuthRedirectComponent
        {...requests}
      />
    )}
  </RequestProvider>
);

export default AuthRedirect;
