import { credentials, getFingerprint, constants } from '@shootsta/client-auth';
import { ActionRequest, LoginData } from '../common/types';
import { LOGIN_SOCIAL_DATA_KEY } from '../common/components/Login/constants/loginSocial';

const { ERROR_MESSAGE } = constants;

type LoginObject = {
  error: string,
  submitting: boolean
};

export default async function login(
  loginReq: ActionRequest<LoginData>,
  payload: Record<string, unknown>,
  { dataKey }: { dataKey: string } = { dataKey: 'login' }
): Promise<LoginObject | null> {
  try {
    const fingerprint = await getFingerprint();

    const result = await loginReq({ ...payload, fingerprint });
    const { data, error } = result;

    if (error || !data || !data[dataKey]) {
      return {
        error: 'Something went wrong. Try again',
        submitting: false
      };
    }

    const { [dataKey]: authData } = data;
    const { auth, errorCode } = authData;

    if (!auth) {
      let errorMessage;

      if (errorCode) {
        errorMessage = ERROR_MESSAGE[errorCode as string];
      } else {
        errorMessage = dataKey === LOGIN_SOCIAL_DATA_KEY
          ? ERROR_MESSAGE.USER_NOT_EXIST
          : ERROR_MESSAGE.EMAIL_PASSWORD_INCORRECT;
      }

      return {
        error: errorMessage,
        submitting: false
      };
    }

    credentials.set(authData);

    return null;
  } catch {
    return {
      error: ERROR_MESSAGE.INTERNAL_SERVER_ERROR,
      submitting: false
    };
  }
}
