import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

import LoginComponent from '../components/Login';
import { loginApi } from '../../../api';
import { ActionRequest, LoginData } from '../../../types';

type RequestProps = {
  login: ActionRequest<LoginData>
};

const Login = ({ history }: { history: History }) => (
  <RequestProvider
    requests={{
      login: loginApi()
    }}
  >
    {(props: RequestProps) => (
      <LoginComponent
        history={history}
        loginRequest={props.login}
      />
    )}
  </RequestProvider>
);

export default withRouter(Login);
