import React, { Component, ReactNode } from 'react';
import cx from 'classnames';
import memoizeOne from 'memoize-one';
import { History } from 'history';
import { BaseModal, FormModal } from '@shootsta/common-react';

import AnonymousUserForm from './AnonymousUserForm';
import AnonymousCommenterForm from './AnonymousCommenterForm';
import { login } from '../../../../utils';
import { getFirstAndLastNameByName } from '../utils';
import { ActionRequest, LoginData } from '../../../types';

import '../styles/_welcome-guest.scss';

type WelcomeGuestModalProps = {
  loginAnonymousUserRequest: ActionRequest<LoginData>,
  history: History,
  isAnonymousCommenter?: boolean
};

type WelcomeGuestModalState = {
  submitting: boolean,
  isClosed?: boolean,
  error?: string,
  lastName?: string,
  firstName?: string
};

export default class WelcomeGuestModal extends Component<
WelcomeGuestModalProps,
WelcomeGuestModalState
> {
  updateField = memoizeOne((field: string) => ({
    target: { value } }: { target: { value: string }
  }) => {
    if (field === 'name') { // AnonymousCommenterForm has one input for first & last name
      const { firstName, lastName } = getFirstAndLastNameByName(value);
      this.setState({ firstName, lastName });
    } else {
      this.setState({ [field]: value } as Pick<WelcomeGuestModalState, 'firstName' | 'lastName'>);
    }
  });

  constructor(props: WelcomeGuestModalProps) {
    super(props);

    this.state = {
      submitting: false
    };
  }

  goToLogin = (): void => {
    const { history } = this.props;
    const { search = '' } = history.location;

    history.push(`/${search}`);
  };

  loginAnonymousUser = async (): Promise<void> => {
    const { firstName, lastName } = this.state;
    const { loginAnonymousUserRequest, history } = this.props;
    const { search = '' } = history.location;

    await this.login(
      loginAnonymousUserRequest,
      { firstName, lastName },
      { dataKey: 'loginAnonymousUser' }
    );

    return void history.push(`/${search}`);
  };

  login = async (
    loginRequest: ActionRequest<LoginData>,
    payload: Record<string, unknown>,
    dataKey?: { dataKey: string }
  ): Promise<void> => {
    this.setState({ error: '', submitting: true });

    const result = await login(loginRequest, payload, dataKey);

    if (!result) { return; }

    this.setState(result);
  };

  render(): ReactNode {
    const { isAnonymousCommenter } = this.props;
    const { submitting, error, firstName, lastName } = this.state;

    const anonymousUserForm = isAnonymousCommenter
      ? (
        <FormModal
          visible
          title="Let people know who you are!"
          hideFooter
          closeOnEsc={false}
          closeMaskOnClick={false}
          showHeaderCloseButton={false}
          subtitle="To start commenting on this video, please enter your name below or log in if you already have a Shootsta account."
          onClose={Function.prototype}
        >
          <AnonymousCommenterForm
            updateName={this.updateField('name')}
            displayLoginForm={this.goToLogin}
            handleAnonymousUser={this.loginAnonymousUser}
            busy={submitting}
            error={error}
            disabled={!firstName}
          />
        </FormModal>

      )
      : (
        <BaseModal
          onClose={Function.prototype}
          className="welcome-guest__modal"
          visible
        >
          <AnonymousUserForm
            updateFirstName={this.updateField('firstName')}
            updateLastName={this.updateField('lastName')}
            goToLogin={this.goToLogin}
            handleAnonymousUser={this.loginAnonymousUser}
            disabled={!firstName || !lastName}
            busy={submitting}
            error={error}
          />
        </BaseModal>
      );

    return (
      <div
        className={cx(
          'welcome-guest',
          { 'anonymous-commenter-modal': isAnonymousCommenter }
        )}
      >
        {anonymousUserForm}
      </div>
    );
  }
}
