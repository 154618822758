import { init } from '@sentry/browser';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import ReactDOM from 'react-dom';
import 'what-input';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { apiClient } from '@shootsta/client-auth';
import globalConfig from 'react-global-configuration';
import isElectron from 'is-electron';
import config from './config';
import { AppContainer } from './common/containers';
import Routes from './routes';
import { version } from '../package.json';
import './styles/_main.scss';
import { RoutePath } from './common/enums/RoutePath';

if (config.sentryDSN && !isElectron()) {
  init({ dsn: config.sentryDSN, release: `${config.sentryReleasePrefix}${version}` });
}

function onConversationsAPIReady() {
  // eslint-disable-next-line no-multi-assign
  const hsq = window._hsq = window._hsq || [];
  hsq.push(['trackPageView']);

  setTimeout(() => {
    window.HubSpotConversations.widget.refresh();
  }, 1000);
}

if (window.HubSpotConversations) {
  onConversationsAPIReady();
} else {
  window.hsConversationsOnReady = [onConversationsAPIReady];
}

globalConfig.set({
  identityEndpoint: window.location.origin,
  socialGoogleClientId: config.socialGoogleClientId,
  socialLinkedInClientId: config.socialLinkedInClientId,
  socialLinkedInRedirectRoutePath: RoutePath.GetWorkspaces,
  socialAppleClientId: config.socialAppleClientId,
  HIDE_FEATURES: {
    LOGIN_VIA_IDENTITY: false,
    LOGIN_SOCIAL: true
  }
});

const root = document.getElementById('root');

const App = (
  <ApolloProvider client={apiClient({ apiEndpoint: config.apiEndpoint })}>
    <ApolloConsumer>
      {() => (
        <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey as string}>
          <AppContainer>
            <Routes />
          </AppContainer>
        </GoogleReCaptchaProvider>
      )}
    </ApolloConsumer>
  </ApolloProvider>
);

if (root != null) {
  ReactDOM.render(App, root);
}
