import { gql } from 'apollo-boost';
import { AnonLoginProps, GraphQLAnonLogin } from '../../../types';

export const loginAnonymousUser = gql`
  mutation ($firstName: String!, $lastName: String, $fingerprint: String) {
    loginAnonymousUser(firstName: $firstName, lastName: $lastName, fingerprint: $fingerprint) {
      auth
      token
      user {
        userId
        firstName
        lastName
        isAnonymous
      }
    }
  }
`;

export default (): GraphQLAnonLogin => ({
  mutation: loginAnonymousUser,
  module: 'identity',
  buildVariables: (
    { firstName, lastName, fingerprint }: AnonLoginProps
  ) => ({ firstName, lastName, fingerprint })
});
