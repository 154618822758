import React, { FC } from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import WorkspaceComponent from '../components/Workspace';
import { getOrgNameFromAliasApi } from '../api';
import GetOrganisationNameFromAlias from '../types/GetOrganisationNameFromAlias';

interface Requests {
  getOrganisationNameFromAlias: GetOrganisationNameFromAlias;
}

const Workspace: FC<RouteComponentProps> = ({ history }) => (
  <RequestProvider requests={{
    getOrganisationNameFromAlias: getOrgNameFromAliasApi()
  }}
  >
    {(requests: Requests) => (
      <WorkspaceComponent
        history={history}
        {...requests}
      />
    )}
  </RequestProvider>
);

export default withRouter(Workspace);
