import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import AuthProtectedComponent from '../components/AuthProtected';
import { refreshTokenApi } from '../api';
import { ActionRequest } from '../../../types';

type AuthProtectedProps = {
  publicFallback: JSX.Element,
  children?: JSX.Element,
  userIsRequired?: boolean,
  hideWelcomeGuestLogin?: ActionRequest,
  location?: Location
};

function AuthProtected(props: AuthProtectedProps): JSX.Element {
  return (
    <RequestProvider
      requests={{
        refreshToken: refreshTokenApi()
      }}
    >
      {({ refreshToken }: { login: ActionRequest, refreshToken: ActionRequest }) => (
        <AuthProtectedComponent
          refreshToken={refreshToken}
          {...props}
        />
      )}
    </RequestProvider>
  );
}

export default AuthProtected;
