import React, { FC, useCallback } from 'react';
import { Link as UiLink } from '@shootsta/common-react';
import { History } from 'history';
import { RoutePath } from '../../../enums/RoutePath';
import '../styles/_login-form.scss';
import { openHubSpotConversationsWidget } from '../utils';

const LOGIN_HELP_ARTICLE = 'https://help.shootsta.com/en/articles/4547921-shootsta-workspaces';

type LoginHelpProps = {
  workspaceLinkText?: string;
  history: History;
};

function goToLogonHelpArticle() {
  window.open(LOGIN_HELP_ARTICLE, '_blank');
}

const LoginHelp: FC<LoginHelpProps> = ({ history, workspaceLinkText }: LoginHelpProps) => {
  const goToWorkspaceCode = useCallback(() => history.push(RoutePath.GetWorkspaces), [history]);

  return (
    <div className="login-form__body__account">
      <div className="login-form__body__help-link">
        {workspaceLinkText || 'Looking for a different workspace?'}
        {' '}
        <UiLink onClick={goToWorkspaceCode}>
          Find your workspace.
        </UiLink>
      </div>
      <div className="login-form__body__help-link">
        Having trouble signing in? Hit the &apos;
        <button
          type="button"
          id="live_chat_link"
          className="link link--button text--link"
          onClick={openHubSpotConversationsWidget}
        >
          Live Chat
        </button>
        &apos; button to get help right away
      </div>
      <div className="login-form__body__help-link">
        {'Still need help? '}
        <UiLink onClick={goToLogonHelpArticle}>
          Check this out.
        </UiLink>
      </div>
    </div>
  );
};

export default LoginHelp;
