import { Component } from 'react';
import { credentials } from '@shootsta/client-auth';
import { ActionRequest } from '../../../types';

type Props = {
  logout: ActionRequest;
};

class Logout extends Component<Props> {
  async componentDidMount(): Promise<void> {
    const { logout } = this.props;
    const { token } = credentials.get();

    if (token) {
      await logout();
    }

    credentials.clear();

    window.location.replace('/');
  }

  render(): null {
    return null;
  }
}

export default Logout;
