import React, { FC } from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { useHistory } from 'react-router-dom';
import VerificationComponent from '../components/Verification';
import { generateVerificationCode, getOrgsForUserCodeApi } from '../api';
import GenerateVerificationCode from '../types/GenerateVerificationCode';
import GetOrgsForUserCode from '../types/GetOrgsForUserCode';

interface Requests {
  generateVerificationCode: GenerateVerificationCode;
  getOrgsForUserCode: GetOrgsForUserCode;
}

const Verification: FC = () => {
  const history = useHistory();

  return (
    <RequestProvider requests={{
      generateVerificationCode: generateVerificationCode(),
      getOrgsForUserCode: getOrgsForUserCodeApi()
    }}
    >
      {(requests: Requests) => (
        <VerificationComponent
          history={history}
          {...requests}
        />
      )}
    </RequestProvider>
  );
};

export default Verification;
