type Props = {
  condition: boolean,
  failWith?: JSX.Element,
  children?: JSX.Element
};

/**
 *
 * Renders a conditional component
 *
 * @param {boolean} condition
 * @param {Element} failWith
 * @param {Element} children
 *
 * @return {Element}
 */
export default function When({ condition, failWith, children }: Props): JSX.Element | null {
  if (!condition) { return failWith || null; }

  return children || null;
}
