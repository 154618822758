import React from 'react';
import { RequestProvider } from '@shootsta/common-react';
import { withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { History } from 'history';
import ResetPasswordComponent from '../components/ResetPassword';
import { changePasswordApi } from '../api';
import { loginApi } from '../../../common/api';
import { ActionRequest, LoginData, PasswordData } from '../../../common/types';

interface ResetPasswordProps {
  history: History
}

interface Requests {
  changePassword: ActionRequest<PasswordData>,
  loginRequest: ActionRequest<LoginData>
}

const ResetPassword = ({ history }: ResetPasswordProps): JSX.Element => (
  <ApolloConsumer>
    {() => (
      <RequestProvider requests={{
        changePassword: changePasswordApi(),
        loginRequest: loginApi()
      }}
      >
        {(requests: Requests) => (
          <ResetPasswordComponent
            history={history}
            {...requests}
          />
        )}
      </RequestProvider>
    )}
  </ApolloConsumer>
);

export default withRouter(ResetPassword);
