import React, { Component } from 'react';
import { credentials, constants } from '@shootsta/client-auth';
import { History } from 'history';
import isEmail from 'validator/lib/isEmail';
import AuthTypeForm from './AuthTypeForm';
import CodeForm from './CodeForm';
import { WorkspaceWrapper } from '../Workspace';
import { ChooseWorkspaceList } from '../ChooseWorkspace';
import GenerateVerificationCode from '../../types/GenerateVerificationCode';
import GetOrgsForUserCode, { UserOrganisation } from '../../types/GetOrgsForUserCode';
import { CustomAny } from '../../../../types/CustomAny';

const { ERROR_MESSAGE } = constants;

type Props = {
  generateVerificationCode: GenerateVerificationCode;
  getOrgsForUserCode: GetOrgsForUserCode;
  history: History;
};

type State = {
  step: number;
  seconds: number;
  isCounterOn: boolean;
  isResend: boolean;
  email: string;
  code?: string;
  orgs: Array<UserOrganisation>;
  error?: string;
  submitting?: boolean;
};

const TIMER_IN_SECONDS = 60;

export default class VerificationWizard extends Component<Props, State> {
  timer!: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.state = {
      step: 1,
      error: '',
      seconds: TIMER_IN_SECONDS,
      isCounterOn: false,
      isResend: false,
      email: '',
      orgs: []
    };
  }

  setEmail = (value: string): void => {
    this.setState({
      email: value,
      error: ''
    });
  };

  useAnotherAddress = (): void => {
    this.setState({
      step: 1,
      email: ''
    });
  };

  onVerificationCodeEntered = async (code: string): Promise<boolean> => {
    const { getOrgsForUserCode } = this.props;
    const { email } = this.state;

    this.setState({
      submitting: true,
      error: ''
    });

    try {
      const { data } = await getOrgsForUserCode({
        email,
        code: code.toUpperCase()
      });

      if (data && data.getOrgsForUserCode) {
        const organisations = data.getOrgsForUserCode;

        if (!organisations || !organisations.length) {
          this.setState({
            submitting: false,
            error: 'Oops! That code was not valid. Try again.'
          });

          return false;
        }

        clearInterval(this.timer);

        this.setState({
          orgs: organisations,
          submitting: false,
          step: 3,
          seconds: TIMER_IN_SECONDS,
          isResend: false,
          isCounterOn: false
        });
      }
    } catch (e) {
      this.setState({
        submitting: false,
        error: ''
      });
    }

    return true;
  };

  resetError = (): void => {
    this.setState({
      error: ''
    });
  };

  timerTick = (): void => {
    const { seconds } = this.state;

    if (seconds > 0) {
      this.setState({
        seconds: seconds - 1
      });
    } else {
      clearInterval(this.timer);

      this.setState({
        seconds: TIMER_IN_SECONDS,
        isCounterOn: false
      });
    }
  };

  startCountdown = (): void => {
    this.setState({
      isCounterOn: true
    });
    this.timer = setInterval(this.timerTick, 1000);
  };

  canSubmitEmail = (email?: string): boolean => Boolean(email && isEmail(email));

  onEmailFormSubmit = async (): Promise<void> => {
    const { generateVerificationCode } = this.props;
    const { email, isResend } = this.state;

    if (!this.canSubmitEmail(email)) {
      return;
    }

    this.setState({
      submitting: true,
      error: ''
    });

    try {
      const { data } = await generateVerificationCode({ email });

      if (!data || !data.generateVerificationCode) {
        this.setState({
          submitting: false,
          error: 'Oops! Looks like a workspace for the email entered does not exist. Try again.'
        });

        return;
      }

      if (isResend) {
        this.startCountdown();
      }

      this.setState({
        submitting: false,
        isResend: true,
        step: 2
      });
    } catch (e) {
      this.setState({
        submitting: false,
        error: ''
      });
    }
  };

  onSuccessSocial = (data: CustomAny): void => {
    const { history } = this.props;
    const { userAuthData } = data;

    if (userAuthData.auth) {
      credentials.set(userAuthData);
      history.replace('/');
    } else {
      this.setState({
        error: ERROR_MESSAGE.USER_NOT_EXIST
      });
    }
  };

  renderStep = (): JSX.Element => {
    const {
      step,
      error,
      email,
      submitting,
      orgs,
      isCounterOn,
      seconds
    } = this.state;
    if (step === 1) {
      return (
        <AuthTypeForm
          onEmailFormSubmit={this.onEmailFormSubmit}
          onEmailChange={this.setEmail}
          onSuccessSocial={this.onSuccessSocial}
          canSubmitEmail={this.canSubmitEmail}
          submitting={submitting}
          email={email}
          error={error}
        />
      );
    }

    if (step === 2) {
      return (
        <CodeForm
          email={email}
          onComplete={this.onVerificationCodeEntered}
          resetError={this.resetError}
          resendCode={this.onEmailFormSubmit}
          error={error}
          isCounterOn={isCounterOn}
          seconds={seconds}
        />
      );
    }

    return (
      <ChooseWorkspaceList
        organisations={orgs}
        email={email}
        useAnotherAddress={this.useAnotherAddress}
      />
    );
  };

  render(): JSX.Element {
    const {
      step
    } = this.state;

    return (
      <WorkspaceWrapper backgroundType={step === 3 ? 'create' : 'lost'}>
        {this.renderStep()}
      </WorkspaceWrapper>
    );
  }
}
