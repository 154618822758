import { gql } from 'apollo-boost';
import { GraphQLForgotPassword } from '../types';

const FORGOT_PASSWORD_MUTATION = gql`
    mutation forgotPassword($email: String!, $identityEndpoint: String!, $returnTo: String) {
        forgotPassword(email: $email, identityEndpoint: $identityEndpoint, returnTo: $returnTo)
    }
`;

export default (): GraphQLForgotPassword => ({
  mutation: FORGOT_PASSWORD_MUTATION,
  module: 'identity',
  buildVariables: ({ email, identityEndpoint, returnTo }) => ({
    email,
    identityEndpoint,
    returnTo
  })
});
